import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { LuImagePlus } from "react-icons/lu";
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Icon,
  Avatar,
  Divider,
} from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import HorizontalNonLinearStepper from "./HorizontalNonLinearStepper";
import StepperIndicator from "./StepperIndicator";
import { VerificationContext } from "../../context/VerificationContext";
import { BsCheckCircle } from "react-icons/bs";
import "./DrawerComponent.scss";
import { BsArrowLeftCircle, BsBellFill } from "react-icons/bs";
import { MdDeleteOutline, MdOutlineRadioButtonUnchecked } from "react-icons/md";
import { AiOutlineFileDone } from "react-icons/ai";
import { FiDownloadCloud } from "react-icons/fi";
import { BiDotsVertical, BiPhone } from "react-icons/bi";
import { IoVideocam } from "react-icons/io5";
// import { AddBankCardFormComponent } from "./../FormsComponent/FormsComponent";
import { delete_notifications } from "../../config/variables";
import { useParams } from "react-router-dom";
import {
  AddMilestones,
  SubscriptionPayCard,
  ApplyForProjectTab,
  BidsTab,
  CreatePortfolioTab,
  DepositTab,
  DocumentsTab,
  DownloadReportTab,
  GalleryTab,
  LanguageSettingsTab,
  MilestoneTab,
  NotificationSettingsTab,
  NotificationTab,
  PasswordSecuritySettingsTab,
  PortfolioDetailesTab,
  PrivacyPolicySettingsTab,
  StatsSettingsTab,
  TransferTab,
  WithdrawTab,
  WriteProjectReport,
  Livestream,
  ProjectChatRoom,
  SiteInventory,
} from "./Tabs";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { IoMdArrowRoundBack } from "react-icons/io";
import { ProjectsContext } from "../../context/ProjectsContext";
import { TostMessageContext } from "../../context/TostMessage";

function DrawerComponent({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const firstField = React.useRef();

  const { activeStep, stepsContents } = React.useContext(VerificationContext);

  return (
    <>
      <div onClick={onOpen}>{children}</div>

      <Drawer
        isOpen={isOpen}
        placement='right'
        initialFocusRef={firstField}
        onClose={onClose}
        className='DrawerComponent'
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <div className='top-header'>
            <h2>{stepsContents[activeStep].title}</h2>
            <p style={{ fontSize: 14 }}>
              {stepsContents[activeStep].descriptions}
            </p>
          </div>
          <div className='stepper-container'>
            <HorizontalNonLinearStepper stepsContents={stepsContents} />
          </div>
          <DrawerBody>
            <div className='row mt-2 form-container mx-auto'>
              {stepsContents[activeStep].content}
              <div className='col-12 mt-3 d-flex flex-column'>
                <StepperIndicator onClose={onClose} />
              </div>
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default DrawerComponent;

export function MainDrawerComponent({
  children,
  open,
  card,
  projectDetailes,
  project,
  project_id,
  size,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const firstField = React.useRef();
  const [viewChatSettings, setViewChatSettings] = useState(false);
  const { id } = useParams();
  const { userProfile } = React.useContext(AuthContext);
  const { getProjectDetailes } = React.useContext(ProjectsContext);
  const [cookies] = useCookies();
  const { setTostMessage } = React.useContext(TostMessageContext);
  // const { activeStep, stepsContents } = React.useContext(VerificationContext);

  const deleteNotif = async () => {
    const data = {
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        project_id: id,
      },
    };
    try {
      const response = await axios.post(delete_notifications, data, {
        headers: {
          access: cookies.urbexUserToken,
        },
      });
      if (response?.data?.status) {
        setTostMessage({
          messageType: "success",
          message: response.data.response,
        });
        getProjectDetailes();
      } else {
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
      }
    } catch (error) {
      console.log(error);
      setTostMessage({
        messageType: "error",
        message: 'Network error',
      });
    }
  } 

  return (
    <div className='overflow-x-hidden'>
      <div onClick={onOpen}>{children}</div>
      <Drawer onClose={onClose} isOpen={isOpen} size={size ? size : "lg"}>
        <DrawerOverlay />
        <DrawerContent
          // style={{ backgroundColor: "#333" }}
          className='bg-rose-300'
        >
          <DrawerCloseButton />
          <div className='top-header flex flex-row items-center w-[full] justify-between'>
            <h2 className='text-start mx-4 md:mx-10 text-sm md:text-lg font-bold'>
              {card.sub}
            </h2>
            {/* {open === 27 && (
              <div
                className='p-2 md:p-3 mx-4 md:mx-10 flex flex-row items-center rounded-full text-white bg-native-blue'
                onClick={() => {
                  viewChatSettings
                    ? setViewChatSettings(false)
                    : setViewChatSettings(true);
                }}
              >
                {!viewChatSettings ? (
                  <IoVideocam className='mr-0 md:mr-2' />
                ) : (
                  <IoMdArrowRoundBack className='mr-0 md:mr-2' />
                )}{" "}
                <a className='font-bold hidden md:block text-sm'>
                  {viewChatSettings ? `Messages` : `Video Meeting`}
                </a>
              </div>
            )} */}
            {open === 2 && (
              <div
                className='p-2 px-3 md:p-3 md:px-4 cursor-pointer hover:opacity-[0.7] mx-4 md:mx-10 flex flex-row items-center rounded-full text-white bg-native-blue'
                onClick={() => deleteNotif()}
              >
                <MdDeleteOutline className='mr-0 md:mr-2' />
                <a className='font-bold hidden md:block text-sm'>
                  Empty
                </a>
              </div>
            )}
          </div>
          <div className='h-full text-start mx-4 lg:mx-10 overflow-auto custom-scrollbar'>
            {open === 0 && (
              <MilestoneTab
                milestones={projectDetailes?.project_milestones}
                closeTabFunction={onClose}
              />
            )}
            {open === 2 && (
              <NotificationTab
                notifications={projectDetailes?.project_notifications}
              />
            )}
            {open === 3 && (
              <DocumentsTab documents={projectDetailes?.project_documents} />
            )}
            {open === 1 && (
              <TransferTab
                transfers={{
                  withdrawals: projectDetailes?.project_withdrawals,
                  credits: projectDetailes?.project_credits,
                }}
              />
            )}
            {open === 5 && <WithdrawTab closeTabFunction={onClose} />}
            {open === 6 && (
              <ApplyForProjectTab
                project={project}
                closeTabFunction={onClose}
              />
            )}
            {open === 7 && <CreatePortfolioTab />}
            {open === 8 && <PortfolioDetailesTab project={project} />}
            {open === 9 && <NotificationSettingsTab />}
            {open === 10 && <LanguageSettingsTab />}
            {open === 11 && (
              <PasswordSecuritySettingsTab closeTabFunction={onClose} />
            )}
            {open === 12 && <PrivacyPolicySettingsTab />}
            {open === 13 && <StatsSettingsTab />}
            {open === 14 && (
              <DepositTab
                projectDetailes={projectDetailes}
                project_id={project_id}
                closeTabFunction={onClose}
              />
            )}
            {open === 15 && <GalleryTab closeTabFunction={onClose} />}
            {open === 16 && (
              <DownloadReportTab
                reports={project?.project_milestones}
                closeTabFunction={onClose}
              />
            )}
            {open === 17 ? <BidsTab project={project} /> : null}
            {open === 18 ? (
              <AddMilestones closeTabFunction={onClose} project={project} />
            ) : null}
            {open === 19 ? (
              <SubscriptionPayCard
                closeTabFunction={onClose}
                price={card.price}
                plan={card.plan}
                project={project}
              />
            ) : null}
            {open === 25 && (
              <Livestream
                project={projectDetailes}
                closeTabFunction={onClose}
              />
            )}
            {open === 26 && (
              <WriteProjectReport
                project={projectDetailes}
                closeTabFunction={onClose}
              />
            )}
            {open === 27 && (
              <ProjectChatRoom
                project={projectDetailes}
                closeTabFunction={onClose}
                viewChatSettings={viewChatSettings}
                setViewChatSettings={setViewChatSettings}
              />
            )}
            {open === 28 && (
              <SiteInventory project_id={project_id} closeTabFunction={onClose} />
            )}
          </div>
        </DrawerContent>
      </Drawer>
    </div>
  );
}

export function DocumentsCard(props) {
  const [cookies] = useCookies();
  const { userProfile } = React.useContext(AuthContext);

  function downloadPDF(uri, name) {
    const downloadLink = document.createElement("a");
    downloadLink.href = uri;
    downloadLink.download = name;
    downloadLink.target = "_blank"; // Open in a new tab/window

    // Trigger a click event on the link to initiate the download
    downloadLink.click();

    // Remove the link element after the download is initiated
    downloadLink.remove();
  }

  return (
    <div
      onClick={async () => {
        props.setViewDocument(props.document);
      }}
      className='p-3 cursor-pointer rounded-xl bg-zinc-100 transition-all ease-in-out my-3 flex flex-col md:flex-row items-start gap-2 pt-10'
    >
      <div className='flex-1'>
        <div>
          <h5 className='text-[15px] font-bold'>
            {props.document.document_name}
          </h5>
          {props.document.to_be_shared_by == userProfile?.sub_account_id ? (
            <h6 className='text-sm'>
              You have been requested to share this document
            </h6>
          ) : null}
        </div>
        <div className='text-sm'>{props.document.time}</div>
      </div>
      <div className='mt-[0] md:mt-2 flex gap-4 cursor-pointer'>
        <button
          className={`font-bold text-sharp-blue bg-gray-200 py-2 px-4 rounded-full ${
            props.document.is_document_shared ? "text-black" : "text-native-blue"
          }`}
        >
          {props.document.is_document_shared ? "Shared" : "Share"}
        </button>
        <button
          className={`font-bold text-sharp-blue bg-gray-200 py-2 px-4  rounded-full ${
            props.document.is_document_signed ? "text-black" : "text-native-blue"
          }`}
        >
          {props.document.is_document_signed ? "Signed" : "Sign"}
        </button>
      </div>
    </div>
  );
}

export function SavedBankAccountCard() {
  return (
    <div className='bank-card-container d-flex justify-content-between p-3'>
      <div className='d-flex gap-3'>
        <Icon className='my-auto' />
        <div>
          {" "}
          <b>Fidelity Bank</b> <br />
          2345****21
        </div>
      </div>
      <div className='my-auto'>remove</div>
    </div>
  );
}

export function TabPanelComponent({
  btn1Text,
  btn2Text,
  tab1Content,
  tab2Content,
}) {
  return (
    <Tabs>
      <TabList>
        <Tab className='tab-button'>{btn1Text}</Tab>
        <Tab className='tab-button'>{btn2Text}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>{tab1Content}</TabPanel>
        <TabPanel>{tab2Content}</TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export function TransferCard({ icon, data, setSelected, index, formatNumber }) {
  return (
    <div className='TransferCard' onClick={() => setSelected(data)}>
      <div>
        {/* <Icon /> */}
        <img src={icon} alt='' />
      </div>
      <div>
        <h2 className='transferCard-header'>
          {data.account_name} - ₦{formatNumber(data.amount)}
        </h2>
        {/* <p>{data.description}</p> */}
      </div>
      <div className='flex-col'>
        {/* <b>Status</b> */}
        <span>{data.status}</span>
      </div>
      <div className='flex-col'>
        <div className='time'> {data.time}</div>
      </div>
    </div>
  );
}
export function NotificationCard({ data }) {
  return (
    <div className='p-3 rounded-xl bg-gray-50 hover:bg-[#cec8e1] transition-all ease-in-out my-3 flex items-start gap-4'>
      <div className='mt-2'>
        {/* <img src={notification_ball} /> */}
        <Icon as={BsBellFill} w={6} h={6} />
      </div>
      <div className=''>
        <div>
          <h5 className='text-[15px] font-bold'>{data.title}</h5>
          <p className='text-[13px] text-gray-500'>{data.message}</p>
        </div>
        <div className='text-[13px] text-end'> {data.time}</div>
      </div>
    </div>
  );
}
export function MilestoneCard({ data, setSelected, getMilestoneReport }) {
  return (
    <div
      className={`flex px-3 py-3 items-center justify-between my-2 rounded-xl cursor-pointer ${
        data.milestone_started && !data.complete ? "bg-blue-100" : ""
      } ${data.milestone_started && data.complete ? "bg-emerald-100" : ""} ${
        !data.milestone_started &&
        !data.complete &&
        data.delayed_start == "Expected to start today"
          ? "bg-[#ffd561]"
          : ""
      } ${
        !data.milestone_started &&
        !data.complete &&
        data.delayed_start != "" &&
        data.delayed_start != "Expected to start today"
          ? "bg-[#f79c9c]"
          : ""
      } ${
        !data.milestone_started && !data.complete && data.delayed_start == ""
          ? "bg-gray-100"
          : ""
      }`}
      onClick={() => {
        setSelected(data)
        console.log(data.milestone_id)
        getMilestoneReport(data.milestone_id)
      }}
    >
      <div className='flex flex-column items-start'>
        {/* <Icon /> */}
        {/* {data?.complete ? (
          <BsCheckCircle height={20} width={20} />
        ) : (
          <MdOutlineRadioButtonUnchecked height={20} width={20} />
        )} */}
        <div className='font-bold text-[13px]'>
          {data.milestone +
            " (" +
            data.milestone_metric_target_covered +
            " of " +
            data.milestone_metric_target +
            " " +
            data.milestone_metric_system +
            ")"}
        </div>
        <div className='text-xs'>
          <a className='font-bold text-[11px]'>Duration: </a> {data.duration}
        </div>
        {data.milestone_started && (
          <div className='text-xs'>
            <a className='font-bold text-[11px]'>Started: </a>{" "}
            {data.duration_elapsed}
          </div>
        )}
        {data.delayed_start != "" && (
          <div className='text-xs font-bold text-[11px]'>
            {data.delayed_start}
          </div>
        )}
        {/* {data.milestone_metric_target != "" && (<div className="text-xs">{data.milestone_metric_target_covered + ' of ' + data.milestone_metric_target + ' ' + data.milestone_metric_system}</div>)} */}
      </div>
      <div className=''>
        <div className='date text-xs'>
          {"End date: " + data.milestone_end_date}
        </div>
      </div>
    </div>
  );
}
export function BidProfileCard({ bid, setBidId }) {
  return (
    <div className='bg-[#f4f4f4] BidProfileCard'>
      <div className='d-flex gap-3 align-items-center'>
        <Avatar height={74} width={74} src={bid?.contractor_display_picture} />
        <div>
          <h2>{bid?.contractor_name || "Profile Name"}</h2>
          <p className="!leading-1 mb-0 !text-xs sm:!text-sm md:!text-md"><span className='text-native-blue'>Bid amount:</span> {bid?.bid_amount}</p>
          <p className="!leading-1 mb-0 !text-xs sm:!text-sm md:!text-md">
            <span className='text-native-blue'>Submitted:</span> {bid?.time}
          </p>
          {bid?.bid_status && <p className="!leading-1 mb-0"><span className='text-[#00b47a] font-bold !text-xs sm:!text-sm md:!text-md'>Accepted</span></p>}
        </div>
      </div>

      <div className=''>
        <Divider />
        <div className='d-flex justify-content-between'>
          <button
            className='bg-native-blue text-white'
            onClick={() => setBidId(bid?.bid_id)}
          >
            View Details
          </button>
          {/* <button
            className='outline outline-native-blue text-native-blue'
            onClick={() => setBidId(bid?.bid_id)}
          >
            View Application
          </button> */}
        </div>
      </div>
    </div>
  );
}
