import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./OngoingProjectsPage.scss";
import { ProgressBarChart } from "./../../components/ProgressBarComponent/ProgressBarComponent";
import project_icon from "../../assets/icons/project-icon.svg";
import { ProjectsContext } from "./../../context/ProjectsContext";
import { AuthContext } from "../../context/AuthContext";
import { LuConstruction } from "react-icons/lu";
import Marquee from "react-fast-marquee";
import { TiPin, TiPinOutline } from "react-icons/ti";
import { fetch_my_projects_end_point,pin_project_end_point } from "../../config/variables";
import { useCookies } from "react-cookie";
import axios from "axios";
import { TostMessageContext } from "../../context/TostMessage";
import { MdOutlineManageSearch, MdOutlineSupervisedUserCircle } from "react-icons/md";

const filterArray = ["All", "Ongoing", "Completed"];

function OngoingProjectsPage() {
  const [loading, setLoading] = useState(true);
  const { userProfile } = useContext(AuthContext);
  const { userProjects, getUserProjects } = useContext(ProjectsContext);
  const [filterState, setFilterState] = useState("All");
  const {setTostMessage} = useContext(TostMessageContext)
  const [cookies] = useCookies();
  const [searchParam, setSearchParam] = useState("");
  const [projects, setProjects] = React.useState({
    loading: false,
    data: [],
  });
  const [pastProjects, setPastProjects] = useState(null);
  const [ongoingProjects, setOngoingProjects] = useState({
    data: [],
  });
  const [projectsFilter, setProjectsFilter] = useState(null);

  useEffect(() => {
    getUserProjects();
  }, []);

  useEffect(() => {
    if (userProjects.loading) {
      return;
    } else {
      if (userProjects?.data) {
        setPastProjects(userProjects?.data?.past_projects);
        setOngoingProjects(userProjects?.data?.ongoing_projects);
        setProjects({
          data: [
            ...userProjects?.data?.ongoing_projects,
            ...userProjects?.data?.past_projects,
          ],
        });
        setLoading(false);
      }
    }
  }, [userProjects]);

  const pinProject = async (id) => {
    let data = JSON.stringify({
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        project_id: id,
      },
    });

    const headers = {
      access: cookies.urbexUserToken,
      "Content-Type": "application/json",
    };

    axios
      .post(pin_project_end_point, data, { headers })
      .then((response) => {
        if (!response.data.status) {
          setTostMessage({
            messageType: "error",
            message: response.data.response,
          });
        }
        if (response.data.status) {
          getUserProjects();
          setTostMessage({
            messageType: "success",
            message: response.data.response,
          });
        }
      })
      .catch((error) => {
        setTostMessage({
          messageType: "error",
          message: 'Network error, please try again.',
        });
      });
  }

  function filterProjects(i) {
    if (i === 0) {
      return setProjectsFilter({
        filter: false,
        data: null,
      });
    }
    let filterArray = [];
    if (i === 1) {
      projects.data.map((project) => {
        if (project.project_status.project_stage == "IN-REVIEW") {
          filterArray.push(project);
        }
      });
    }
    if (i === 2) {
      projects.data.map((project) => {
        if (project.project_status.project_stage == "ACTIVE") {
          filterArray.push(project);
        }
      });
    }
    if (i === 3) {
      projects.data.map((project) => {
        if (project.project_status.project_stage == "PAUSED") {
          filterArray.push(project);
        }
      });
    }
    if (i === 4) {
      projects.data.map((project) => {
        if (
          project.project_status.project_stage == "PROJECT FINISHED"
        ) {
          filterArray.push(project);
        }
      });
    }
    setProjectsFilter({
      filter: true,
      data: filterArray,
    });
  }

  async function getProjects() {
    const data = {
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        search: searchParam,
        offset: 0,
        records: 30,
      },
    };
    try {
      const response = await axios.post(fetch_my_projects_end_point, data, {
        headers: {
          access: cookies.urbexEnterpriseUserToken,
        },
      });
      if (response.data.statusCode === 401) {
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
        // navigate("/login");
        // forceLogOutFunction();
        return;
      }
      if (!response.data.status) {
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
        return;
      }
      setProjects({
        data: [
          ...response?.data?.ongoing_projects,
          ...response?.data?.past_projects,
        ],
      });
      // // console.log("Projects", userProfile.account_id, response.data);
      // return response.data.projects;
    } catch (error) {
      // console.log(error);
    }
  }

  const search = async (value) => {
    setSearchParam(value);
    if (value.length > 1) {
      getProjects();
    } else {
      setProjects({
        data: [
          ...userProjects?.data?.ongoing_projects,
          ...userProjects?.data?.past_projects,
        ],
      });
      setProjectsFilter({
        filter: false,
        data: null,
      });
    }
  }

  return (
    <div className='OngoingProjectsPage xl:px-20 max-md:px-6 md:px-10'>
      <div className="flex w-full overflow-auto flex-column sm:!flex-row items-center md:items-center justify-between md:gap-x-2 py-3">
        <div className='w-full overflow-auto md:w-auto md:overflow-hidden'>
          <FilterScrollComponent
            filtersArray={[
              "All",
              "In-Review",
              "Ongoing",
              "Paused",
              "Completed",
            ]}
            handleFilerFunction={(i) => filterProjects(i)}
          />
        </div>
        <div className="search-filter-container w-full sm:w-auto">
          <div className="search-box rounded-md !border-none !outline-none w-full sm:w-auto flex flex-row  items-center bg-white justify-between px-2">
            <input type="text" onKeyUp={(e) => {
              if (e.target.value == "") { 
                setProjects({
                  data: [
                    ...userProjects?.data?.ongoing_projects,
                    ...userProjects?.data?.past_projects,
                  ],
                });
                setProjectsFilter({
                  filter: false,
                  data: null,
                });
              } 
              setSearchParam(e.target.value)
            }} placeholder="Search" className="search-input cursor-text focus:!outline-none focus:!border-none !outline-none !border-none" />
            <MdOutlineManageSearch className="cursor-pointer" color={searchParam == "" ? `#000` : `#000aff`} onClick={() => search(searchParam)} size={20} />
          </div>
        </div>
      </div>
      {loading ? (
        <div>
          {Array.from({ length: 8 }).map((i) => (
            <div
              key={i}
              className='h-24 w-full bg-gray-200 animate-pulse rounded-lg py-3 my-7'
            ></div>
          ))}
        </div>
      ) : (
        <>
          {projects?.data?.length ? (
            <>
              {projectsFilter?.filter === true ? (
                    <>
                      {projectsFilter.data === null ||
                      projectsFilter.data.length <= 0 ? (
                        <>Nothing to show...</>
                      ) : (
                        <>
                          {projectsFilter.data?.map((project, i) => {
                            return (
                              <div className='mx-auto' key={i}>
                                <OngoingProjectCard project={project} pinProject={pinProject} />
                              </div>
                            );
                          })}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {projects.data?.map((project, i) => {
                        return (
                          <div className='mx-auto' key={i}>
                            <OngoingProjectCard project={project} pinProject={pinProject} />
                          </div>
                        );
                      })}
                    </>
                  )}
            </>
          ) : (
            <div className='no-project mt-10'>
              <h1>
                {userProfile?.account_type === "contractor"
                  ? "You do not have any projects for this filter"
                  : "You have not created any projects"}
              </h1>
              <Link
                to={
                  userProfile?.account_type === "contractor"
                    ? "/dashboard/search-project"
                    : "/dashboard/create-project"
                }
              >
                {userProfile?.account_type === "contractor"
                  ? "Find Project"
                  : "Create Project"}
              </Link>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default OngoingProjectsPage;

export function OngoingProjectCard({ project, pinProject }) {
  function calculatePercentage(total, completed) {
    const percentage = (completed / total) * 100 || 0;
    return Math.round(percentage);
  }

  return (
    <Link
      to={`./${project?.project_id}`}
      className='projectCard flex p-3 md:p-4 lg:p-8 justify-between items-center h-auto md:h-28 lg:h-28 bg-white rounded-2xl my-3'
    >
      <div className='hidden md:block lg:block'>
        <img src={project_icon} alt='Project Icon' />
        {/* <LuConstruction size={40} color={'#000aff'} /> */}
      </div>
      <div className='w-[40%] md:w-[35%] flex flex-column'>
        <h2 className='text-sm xl:text-md leading-tight'>
          {project?.project_title}
        </h2>
        <span className='text-xs md:text-sm font-bold text-sharp-blue'>{project?.project_status?.project_stage}</span>
        <span className='text-xs md:text-sm'>{project?.site_address}</span>
        <span className='flex flex-row items-center text-xs xl:text-sm leading-tight'>
          <MdOutlineSupervisedUserCircle className="mr-2" />
          <Marquee gradient={true} pauseOnHover={true} gradientWidth={100}>
            <a className="text-[#000]">{project?.created_by_name}</a>
            {project?.project_members_scroll?.map((item, index) => {
              return <a className="mr-1 text-[#000]">, {item.sub_account_name}</a>
            })}
          </Marquee>
        </span>
      </div>
      <div>
        <ProgressBarChart
          percentage={calculatePercentage(
            project?.project_status?.project_milestones || 0,
            project?.project_status?.project_milestones_completed || 0
          )}
        />
      </div>
      <div className='hidden sm:flex flex-column'>
        <span className='text-sm xl:text-md'>Milestones</span>{" "}
        <h1 className='text-[20px]'>
          {project?.project_status?.project_milestones}
        </h1>{" "}
        <span className='text-sm xl:text-md'>
          {project?.project_status?.project_milestones_completed} of{" "}
          {project?.project_status?.project_milestones} completed
        </span>
      </div>
      <Link
        className='p-1 md:p-3'
        onClick={() => pinProject(project?.project_id)}
      >
        {project?.project_pin ? (
          <TiPin size={25} color="#000aff" />
        ) : (
          <TiPinOutline size={25} color="#000aff" />
        )}
      </Link>
      {/* <div>
        <Icon as={BsThreeDots} />
      </div> */}
    </Link>
  );
}

export function FilterScrollComponent({ filtersArray, handleFilerFunction }) {
  const [active, setActive] = React.useState(0);
  return (
    <div className='FilterScrollComponent flex-col'>
      {/* <div className='text-start w-full mt-4'>Filter</div> */}
      <div className='filter-container m-0'>
        {filtersArray?.map((filter, i) => (
          <button
            key={i}
            className={active === i ? "active text-sm text-white" : " text-sm"}
            onClick={() => {
              setActive(i);
              handleFilerFunction(i);
            }}
          >
            {filter}
          </button>
        ))}
      </div>
    </div>
  );
}
