import React from 'react';
import { Link, useParams } from 'react-router-dom';
import './Subscriptions.scss';
import { ProgressBarChart } from './../../components/ProgressBarComponent/ProgressBarComponent';
import { 
  Icon,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer } from '@chakra-ui/react';
import project_icon from '../../assets/icons/project-icon.svg';
import { BsThreeDots } from 'react-icons/bs';
import { AiOutlineCheck, AiOutlineMinus } from 'react-icons/ai';
import { BsArrowLeftCircle, BsTrash, BsCheck2Circle } from 'react-icons/bs';
import { ProjectsContext } from './../../context/ProjectsContext';
import { AuthContext } from '../../context/AuthContext';
import { MainDrawerComponent } from '../../components/AccountSetUpCard/DrawerComponent';
import { TostMessageContext } from '../../context/TostMessage';

function SubscriptionsPage() {
  const [loading, setLoading] = React.useState(true);
  const {id} = useParams();
  const { projectDetailes, getProjectDetailes } = 
  React.useContext(ProjectsContext);
  const { setTostMessage } = React.useContext(TostMessageContext);

  React.useEffect(() => {
    getProjectDetailes();
  });

  return (
    <div className="OngoingProjectsPage xl:px-10 max-md:px-6 md:px-10">
      <div className="flex flex-wrap justify-between items-center">
      <MainDrawerComponent
        projectDetailes={projectDetailes?.data?.project_account_details}
        open={19}
        className="max-md:w-[100%] md:w-[100%] items-center"
        card={{ sub: 'Tier 1 Subscription', plan: 'Tier 1', 'price': 66 }}
        >
            <div className="h-auto py-3 px-4 my-4 w-[100%] border-3 hover:border-native-blue rounded-[10px] items-center justify-center bg-transparent flex">
                <div className='flex flex-column pr-3'>
                    <a className='font-bold text-[12px]'>Tier 1</a>
                    <div>
                        <h2 className='text-native-blue text-[25px] font-bold mb-0'>$66</h2>
                        <span className='text-[12px]'>per month</span>
                    </div>
                </div>
                <div className='px-0'>
                    <a className='font-bold text-[12px]'>What you get</a>
                    <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>Dedicated project manager</a></span>
                    <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>3 Site visits monthly</a></span>
                    <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>Realtime Milestone & project reports.</a></span>
                    <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>Unlimited calls & chats.</a></span>
                    <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>Add-on Extra site visits.</a></span>
                </div>
            </div>
        </MainDrawerComponent>
        <MainDrawerComponent
        projectDetailes={projectDetailes?.data?.project_account_details}
        open={19}
        className="max-md:w-[100%] md:w-[100%]"
        card={{ sub: 'Tier 2 Subscription', plan: 'Tier 2', 'price': 110 }}
        >
        <div className="h-auto py-3 my-4 px-4 w-[100%] border-3 hover:border-native-blue rounded-[10px] items-center justify-center bg-transparent flex">
            <div className='flex flex-column pr-3'>
                <a className='font-bold text-[12px]'>Tier 2</a>
                <div>
                    <h2 className='text-native-blue text-[25px] font-bold mb-0'>$110</h2>
                    <span className='text-[12px]'>per month</span>
                </div>
            </div>
            <div className='px-0'>
                <a className='font-bold text-[12px]'>What you get</a>
                <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>Dedicated project manager</a></span>
                <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>6 Site visits monthly</a></span>
                <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>Realtime Milestone & project reports.</a></span>
                <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>Unlimited calls & chats.</a></span>
                <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>Add-on Extra site visits.</a></span>
                <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>Add-on site livestream option.</a></span>
            </div>
        </div>
        </MainDrawerComponent>
        <MainDrawerComponent
        projectDetailes={projectDetailes?.data?.project_account_details}
        open={19}
        className="max-md:w-[100%] md:w-[100%]"
        card={{ sub: 'Tier 2 Subscription', plan: 'Tier 2', 'price': 320 }}
        >
        <div className="h-auto py-3 my-4 px-4 w-[100%] border-3 hover:border-native-blue rounded-[10px] items-center justify-center bg-transparent flex">
            <div className='flex flex-column pr-3'>
                <a className='font-bold text-[12px]'>Tier 3</a>
                <div>
                    <h2 className='text-native-blue text-[25px] font-bold mb-0'>$300</h2>
                    <span className='text-[12px]'>per month</span>
                </div>
            </div>
            <div className='px-0'>
                <a className='font-bold text-[12px]'>What you get</a>
                <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>Dedicated project manager</a></span>
                <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>12 Site visits monthly</a></span>
                <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>Realtime Milestone & project reports.</a></span>
                <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>Unlimited calls & chats.</a></span>
                <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>Site livestream option.</a></span>
            </div>
        </div>
        </MainDrawerComponent>
      </div>
      <div className='flex flex-column px-10 justify-start bg-white rounded-[10px] pt-4'>
      <div className='flex flex-row w-100 py-2 items-center justify-end'>
        {projectDetailes?.data?.subscription.length < 1 ? (
          <button onClick={() => {
            setTostMessage({
              messageType: 'warning',
              message: 'No active subscription, please subscribe before buying add-ons.',
            });
          }} className='bg-[#102b53] text-white p-2 px-3 text-[16px] rounded-[5px] mx-10'>Buy Add-ons</button>
        ) : (
          <MainDrawerComponent open={19} projectDetailes={projectDetailes?.data?.project_account_details} card={{ sub: 'Add-ons', plan: 'Add-ons', 'price': 0 }}><button className='bg-[#102b53] text-white p-2 px-3 text-[16px] rounded-[5px] mx-10'>Buy Add-ons</button></MainDrawerComponent>
        )}
      </div>
      <TableContainer>
        <Table variant='striped' size="md">
          <TableCaption>Subscriptions</TableCaption>
          <Thead>
            <Tr>
              <Th>Subscription</Th>
              <Th>Status</Th>
              <Th isNumeric>Duration</Th>
              <Th isNumeric>Site Visits</Th>
              <Th>Livestream (Installed, Active)</Th>
              <Th>Started</Th>
              <Th>Expiry</Th>
            </Tr>
          </Thead>
          <Tbody>
            {projectDetailes?.data?.subscription.length < 1 ? (
              <Tr>
                <Td>
                  No active subscription...
                </Td>
              </Tr>
            ) : (
              <Tr>
                <Td>{projectDetailes?.data?.subscription?.plan}</Td>
                {!projectDetailes?.data?.subscription?.expired ? <Td className="text-[#00b47a] font-bold text-[15px]">Active</Td> : <Td className="text-[#ff7300] font-bold text-[15px]">Expired</Td>}
                <Td isNumeric>{projectDetailes?.data?.subscription?.duration} Month(s)</Td>
                <Td isNumeric>{projectDetailes?.data?.subscription?.site_visits_left}</Td>
                <Td>{`${projectDetailes?.data?.subscription?.livestream_feed_installed}, ${projectDetailes?.data?.subscription?.livestream_feed_active}`}</Td>
                <Td>{projectDetailes?.data?.subscription?.started}</Td>
                <Td>{projectDetailes?.data?.subscription?.expiry}</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      </div>
    </div>
  );
}

export default SubscriptionsPage;