export const base_url = "https://api.urbex.africa/backend";
export const register_end_point = `${base_url}/auth/signup.php`;
export const auto_login_endpoint = `${base_url}/auth/auto_login.php`;
export const weekly_report = `${base_url}/projects/add-weekly-report.php`;
export const Add_documents = `${base_url}/projects/add_documents.php`;
export const messaging_token_endpoint = `${base_url}/messaging/notification/save_token.php`;
export const get_weekly_report = `${base_url}/projects/fetch_weekly_reports.php`;
export const login_end_point = `${base_url}/auth/login.php`;
export const verify_OTP_end_point = `${base_url}/auth/verify-otp.php`;
export const request_OTP_end_point = `${base_url}/auth/resetpassword.php`;
export const change_password_end_point = `${base_url}/auth/change_password.php`;
export const fetch_notifications_end_point = `${base_url}/notifications/fetch_notifications.php`;
export const verify_personal_information_end_point = `${base_url}/profile/personal_information.php`;
export const verify_contractor_information_end_point = `${base_url}/profile/contractor_information.php`;
export const persist_user_end_point = `${base_url}/auth/persist_user.php`;
export const search_projects_end_point = `${base_url}/projects/search_projects.php`;
export const select_project_by_id_end_point = `${base_url}/projects/select_my_project_by_id.php`;
export const client_profile_end_point = `${base_url}/profile/client_profile.php`;
export const fetch_user_profile_end_point = `${base_url}/profile/fetch_user_profile.php`;
export const fetch_my_projects_end_point = `${base_url}/projects/fetch_my_projects.php`;
export const bid_for_project_end_point = `${base_url}/projects/bid_for_project.php`;
export const project_applications_end_point = `${base_url}/projects/project_applications.php`;
export const project_bids_end_point = `${base_url}/projects/list_project_bids.php`;
export const view_user_financials_end_point = `${base_url}/financials/view_user_financials.php`;
export const add_to_portfolio_end_point = `${base_url}/profile/add_to_portfolio.php`;
export const file_upload_endpoint = `https://api.urbex.africa/messaging/upload_media.php`;
export const submit_withdraw_request = `${base_url}/financials/request_payout.php`;
export const fetch_bank_list_endpoint = `${base_url}/financials/fetch_bank_list.php`;
export const start_milestone_end_point = `${base_url}/projects/start_milestone.php`;
export const complete_milestone_end_point = `${base_url}/projects/complete_milestone.php`;
export const fetch_bid_details_end_point = `${base_url}/projects/fetch_bid_details.php`;
export const download_milestone_report_end_point = `${base_url}/reports/milestone.php`;
export const assign_contractor_end_point = `${base_url}/projects/assign_contractor.php`;
export const add_project = `${base_url}/projects/create_project.php`;
export const add_milestones_end_point = `${base_url}/projects/add_milestones.php`;
export const upload_milestone_csv_endpoint = `${base_url}/projects/add_milestones_csv.php`;
export const pay_with_wallet_endpoint = `${base_url}/wallet/in-app-purchases.php`;
export const start_project_end_point = `${base_url}/projects/start_project.php`;
export const approve_contractor_end_point = `${base_url}/projects/approve_contractor.php`;
export const logged_in_password_change = `${base_url}/auth/logged_in_password_change.php`;
export const save_user_profile_end_point = `${base_url}/profile/save_user_profile.php`;
export const update_notifications_endpoint = `${base_url}/profile/update_notifications.php`;
export const fetch_notifications_endpoint = `${base_url}/profile/fetch_notifications.php`;
export const fetch_messages_endpoint = `https://api.urbex.africa/messaging/fetch_messages.php`;
export const pay_online_endpoint = `${base_url}/payments/initialize_payment.php`;
export const fetch_milestone_end_point = `${base_url}/projects/fetch_milestone_reports.php`;
export const project_request_end_point = `${base_url}/projects/contractor_requests.php`;
export const accept_project_request = `${base_url}/projects/accept_project_request.php`;
export const fetch_account_name_endpoint = `${base_url}/financials/resolve_account_name.php`;
export const pin_project_end_point = `${base_url}/projects/pin_projects.php`;
export const get_budget_summary = `${base_url}/financials/budget_report.php`;
export const add_project_funds = `${base_url}/financials/add_project_funds.php`;
export const remove_project_funds = `${base_url}/financials/remove_project_funds.php`;
export const edit_milestone_cost = `${base_url}/projects/edit_milestone_cost.php`;
export const delete_notifications = `${base_url}/projects/delete_notifications.php`;
export const get_inventory_report = `${base_url}/projects/get_inventory_report.php`;
export const create_inventory_item = `${base_url}/projects/create_inventory_item.php`;
export const manage_inventory = `${base_url}/projects/manage_inventory.php`;
