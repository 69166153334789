import axios from "axios";
import React, { createContext, useContext, useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import {
  change_password_end_point,
  login_end_point,
  auto_login_endpoint,
  persist_user_end_point,
  register_end_point,
  request_OTP_end_point,
  verify_OTP_end_point,
} from "../config/variables";
import { useNavigate, useLocation } from "react-router-dom";
import { TostMessageContext } from "./TostMessage";

export const AuthContext = createContext();
// 12345678@Abc
export function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const { setTostMessage } = useContext(TostMessageContext);
  const [isLoading, setIsLoading] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies();
  const location = useLocation();
  const [resetOtpSent, setResetOtpSent] = useState(false);

  useEffect(() => {
    // // console.log("Path", location.pathname.split("/"));
    if (cookies.urbexUserToken && cookies.urbexUserToken !== "") {
      getUserProfileFunction();
    } else {
      if (location.pathname.split("/")[1] === "dashboard") {
        setTostMessage({
          messageType: "error",
          message: "Session expired. Please Login",
        });
        navigate(`/login`);
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  }, []);
  const navigate = useNavigate();
  // useEffect(() => {
  //   getUserProfileFunction();
  // }, []);
  async function getUserProfileFunction() {
    const date = new Date();
    let expiresDate = date.setTime(date.getTime() + 24 * 60 * 60 * 1000); // 24 hours
    if (cookies.urbexUserToken) {
      const data = {
        user_token: cookies.urbexUserToken,
        account_id: cookies.userAccountIds?.account_id,
        sub_account_id: cookies.userAccountIds?.sub_account_id,
      };
      try {
        const response = await axios.post(persist_user_end_point, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.data.statusCode === 200) {
          setIsLoggedIn(true);
          setUserProfile(response.data.user);
          setIsLoggedIn(true);
          const Ids = {
            account_id: response.data.user.account_id,
            sub_account_id: response.data.user.sub_account_id,
          };
          setCookie("userAccountIds", Ids, {
            path: "/",
            maxAge: expiresDate,
            secure: true,
          });
          setUserProfile(response.data.user);
          setCookie("urbexUserToken", response.data.token, {
            path: "/",
            maxAge: expiresDate,
          });
          setCookie("userProfile", response.data.user, {
            path: "/",
            maxAge: expiresDate,
          });
          setIsLoading(false);
          if (location.pathname === "/login") {
            navigate(`/dashboard/home`);
            setIsLoading(false);
          }
          return;
        }
        if (response.data.statusCode === 401) {
          setTostMessage({
            messageType: "Token error",
            message: response.data.response,
          });
          setUserProfile({});
          setIsLoggedIn(false);
          removeCookie("userProfile", { path: "/" });
          removeCookie("urbexUserToken", { path: "/" });
          navigate("/login");
          setIsLoading(false);
          return;
        }
        if (!response.data.status) {
          navigate("/login");
          setTostMessage({
            messageType: "Token error",
            message: response.data.response,
          });
          setIsLoading(false);
          return;
        }
      } catch (error) {
        // console.log(error);
        setIsLoading(false);
      }
    }
  }
  async function RegistrationFunction(data, setLoading, reset) {
    try {
      const response = await axios.post(register_end_point, {
        email: data.email,
        password: data.password,
        first_name: data.first_name,
        last_name: data.last_name,
        phone_number: data.phone_number,
        account_type: data.account_type,
        title:data.title
      });
      if (response.data.statusCode !== 201 || response.data.status === false) {
        setTostMessage({
          messageType: "Registration error",
          message: response.data.response,
        });
        setLoading(false);
        return;
      }
      
      sessionStorage.setItem(
        "PMP2022SAVELOGIN",
        JSON.stringify({
          email: data.email,
          password: data.password,
        })
      );

      navigate(`/verify/${JSON.parse(response.config.data).email}`);
    } catch (error) {
      setLoading(false);
    }
  }
  async function LoginFunction(data, setLoading) {
    try {
      const response = await axios.post(login_end_point, {
        ...data,device_type:'WEB'
      });
      if (response.data.statusCode === 402) {
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
        sessionStorage.setItem(
          "PMP2022SAVELOGIN",
          JSON.stringify({
            email: data.email,
            password: data.password,
            device_type:'WEB'
          })
        );
        return navigate(`/verify/${JSON.parse(response.config.data).email}`);
      }
      if (response.data.statusCode === 401) {
        setTostMessage({
          messageType: "Token error",
          message: response.data.response,
        });
        setUserProfile({});
        setIsLoggedIn(false);
        removeCookie("userProfile", { path: "/" });
        removeCookie("urbexUserToken", { path: "/" });
        navigate("/login");
        return;
      }

      if (!response.data.status) {
        setTostMessage({
          messageType: "Token error",
          message: response.data.response,
        });
        setLoading(false);
        return;
      }
      const date = new Date();
      let expiresDate = date.setTime(date.getTime() + 24 * 60 * 60 * 1000); // 24 hours
      setUserProfile(response.data.user);
      const Ids = {
        account_id: response.data.user.account_id,
        sub_account_id: response.data.user.sub_account_id,
      };
      setCookie("userAccountIds", Ids, {
        path: "/",
        maxAge: expiresDate,
        secure: true,
      });

      setCookie("userProfile", response.data.user, {
        path: "/",
        maxAge: expiresDate,
      });

      setCookie("urbexUserToken", response.data.token, {
        path: "/",
        maxAge: expiresDate,
      });

      // setTostMessage({
      //   messageType: "success",
      //   message: response.data.response,
      // });
      // navigate  to dashboard
      navigate("/dashboard/home");
      setIsLoggedIn(true);
    } catch (error) {
      //console.error(error.response);
      setLoading(false);

      // return false;
    }
  }
  async function VerifyOTPFunction(data, setLoading) {
    var loginObject = JSON.parse(sessionStorage.getItem("PMP2022SAVELOGIN"));
    // console.log("loginObject", loginObject);
    if (data.email === "") {
      setTostMessage({
        messageType: "error",
        message: "Email is required",
      });
      return;
    }
    if (data.otp === "") {
      setTostMessage({
        messageType: "error",
        message: "OTP is required",
      });
      return;
    }
    // return // console.log(data);
    try {
      const response = await axios.post(verify_OTP_end_point, {
        ...data,
        // code: data.otp,
        // email: data.email,
        type: "verify-email",
      });

      if (response.data.statusCode == 401) {
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
        setLoading(false);
        return;
      }
      setTostMessage({
        messageType: "success",
        message: response.data.response,
      });
      LoginFunction(loginObject, setLoading);
      //sessionStorage.removeItem("PMP2022SAVELOGIN");
      // navigate  to dashboard
      //navigate("/login");
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }
  async function SendOtpForPasswordRestFunction(data, setLoading) {
    if (data.email === "") {
      setTostMessage({
        messageType: "error",
        message: "Email is required",
      });
      return;
    }

    // return setResetOtpSent(true);
    try {
      const response = await axios.post(request_OTP_end_point, {
        ...data,
        type: "password-reset",
      });
      console.log({ response });
      if (response.data.status === false) {
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
        setLoading(false);
        return;
      }
      setTostMessage({
        messageType: "success",
        message: "Email sent successfully",
      });
      setResetOtpSent(true);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  async function SendOtpForEmailVerificationFunction(data) {
    if (data.email === "") {
      setTostMessage({
        messageType: "error",
        message: "Email is required",
      });
      return;
    }

    try {
      const response = await axios.post(request_OTP_end_point, {
        ...data,
        type: "verify-email",
      });

      if (response.data.status === false) {
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
        return;
      }
      // // console.log(response);
      setTostMessage({
        messageType: "success",
        message: "Email sent successfully",
      });
      // setResetOtpSent(true);
    } catch (error) {
      console.error(error);
    }
  }
  async function ChangePasswordFunction(data, setLoading) {
    if (data.email === "") {
      setTostMessage({
        messageType: "error",
        message: "Email is required",
      });
      return;
    }
    if (data.code === "") {
      setTostMessage({
        messageType: "error",
        message: "code is required",
      });
      return;
    }
    if (data.new_password === "") {
      setTostMessage({
        messageType: "error",
        message: "New password is required",
      });
      return;
    }
    if (data.confirm_new_password === "") {
      setTostMessage({
        messageType: "error",
        message: "Confirm new password is required",
      });
      return;
    }

    try {
      const response = await axios.post(change_password_end_point, {
        ...data,
      });
      if (response.data.status === false) {
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
        setLoading(false);
        return;
      }
      // // console.log(response);
      setTostMessage({
        messageType: "success",
        message: response.data.response,
      });
      navigate("/login");
    } catch (error) {
      console.error(error);
    }
  }

  function logOutFunction() {
    if (!window.confirm("Are you sure you want to log out")) {
      return;
    }
    // localStorage.removeItem("");

    // setUserAccountInformation([]);
    setUserProfile({});
    setIsLoggedIn(false);
    removeCookie("userProfile", { path: "/" });
    removeCookie("urbexUserToken", { path: "/" });
    removeCookie("userAccountIds", { path: "/" });
    navigate("./");
  }

  function forceLogOutFunction() {
    setUserProfile({});
    setIsLoggedIn(false);
    removeCookie("userProfile", { path: "/" });
    removeCookie("urbexUserToken", { path: "/" });
    removeCookie("userAccountIds", { path: "/" });
    navigate("/login");
  }

  async function AutoLoginFunction(data, setLoading) {
    setUserProfile({});
    setIsLoggedIn(false);
    removeCookie("userProfile", { path: "/" });
    removeCookie("urbexUserToken", { path: "/" });
    try {
      const response = await axios.post(auto_login_endpoint, {
        token: data, device_type:'WEB'
      });
      if (response.data.statusCode === 402) {
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
        navigate("/login");
      }

      if (response.data.statusCode === 401) {
        setTostMessage({
          messageType: "Token error",
          message: response.data.response,
        });
        setUserProfile({});
        setIsLoggedIn(false);
        removeCookie("userProfile", { path: "/" });
        removeCookie("urbexUserToken", { path: "/" });
        navigate("/login");
        return;
      }

      if (!response.data.status) {
        setTostMessage({
          messageType: "Token error",
          message: response.data.response,
        });
        setLoading(false);
        navigate("/login");
        return;
      }

      const date = new Date();
      let expiresDate = date.setTime(date.getTime() + 24 * 60 * 60 * 1000); // 24 hours
      setUserProfile(response.data.user);
      const Ids = {
        account_id: response.data.user.account_id,
        sub_account_id: response.data.user.sub_account_id,
      };
      setCookie("userAccountIds", Ids, {
        path: "/",
        maxAge: expiresDate,
        secure: true,
      });

      setCookie("userProfile", response.data.user, {
        path: "/",
        maxAge: expiresDate,
      });

      setCookie("urbexUserToken", response.data.token, {
        path: "/",
        maxAge: expiresDate,
      });

      // setTostMessage({
      //   messageType: "success",
      //   message: response.data.response,
      // });
      // navigate  to dashboard
      navigate("/dashboard/home");
      setIsLoggedIn(true);
    } catch (error) {
      //console.error(error.response);
      setLoading(false);

      // return false;
    }
  }

  return (
    <AuthContext.Provider
      value={{
        logOutFunction,
        forceLogOutFunction,
        isLoggedIn,
        RegistrationFunction,
        AutoLoginFunction,
        isLoading,
        setIsLoading,
        LoginFunction,
        VerifyOTPFunction,
        SendOtpForPasswordRestFunction,
        SendOtpForEmailVerificationFunction,
        ChangePasswordFunction,
        resetOtpSent,
        setResetOtpSent,
        userProfile,
        getUserProfileFunction,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
